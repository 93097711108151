const environment = process.env.REACT_APP_NODE_ENV || 'development';
const port = parseInt(process.env.PORT) || 3333;

const config = Object.freeze({
  ENVIRONMENT: environment,
  IS_PRODUCTION: environment === 'production',
  IS_STAGING: environment === 'staging',
  IS_DEVELOPMENT: environment === 'development',
  IS_TEST: environment === 'test',
  PORT: port,
  REGION: process.env.REACT_APP_REGION,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  API_WF_INGRESS_URL: process.env.REACT_APP_API_WF_INGRESS_URL,
  LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
  APP_WS_BASE_URL: process.env.REACT_APP_WS_BASE_URL,
  APP_NAME: process.env.REACT_APP_NAME,
});

export default config;
