import {useCallback, useEffect, useRef, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Modal } from "flowbite-react";
import Button from "../../components/button/button";
import TextBox from "../../components/forms/text-box";
import TextArea from "../../components/forms/text-area";
import Message from "../../components/toast-message";
import FormLoader from "../../components/form-loader/form-loader";

const projects = [
    { id: 'greet', name: 'Greet to user', initials: 'GU', href: '#', members: 1, bgColor: 'bg-green-500' },
    { id: 'appointment-book', name: 'Appointment Booking', initials: 'AB', href: '#', members: 5, bgColor: 'bg-pink-600' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function TemplateSelector({onClose}) {
    const rootRef = useRef(null);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        templateId: '',
    });

    const [dataLoadingState, setDataLoading] = useState({
        processing: false,
        success: false,
        failed: false,
        message: null
    });

    const [error, setError] = useState({
        templateId: false,
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        const newForm = {...formData}
        newForm[targetName] = targetValue
        setFormData(newForm);
    });

    const validateForm = () => {
        let valid = true;
        if (!formData.templateId) {
            error.templateId = true;
            valid = false;
        } else {
            error.templateId = false;
        }

        setError({...error})
        return valid;
    }


    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            onClose(formData.templateId)
        }
    };

    const buttonText = "Set Topic";
    return (
        <div ref={rootRef}>
            <Modal show={true} size="xl" popup onClose={onClose} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleOnSubmit}>
                        <div className="space-y-6">

                            {formState.processing && <FormLoader relative={true}/>}

                            {formState.success && <Message text="Successfully Created New Journey" type="success"/>}

                            {formState.failed && <Message text={formState.message} type="failure"/>}

                            <div>
                                <h2 className="text-lg font-medium text-gray-500">Select Template</h2>
                                <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:gap-6">
                                    {projects.map((project) => (
                                        <li onClick={event => {onClose(project.id)}} key={project.name} className="col-span-1 flex rounded-md shadow-sm">
                                            <div
                                                className={classNames(
                                                    project.bgColor,
                                                    'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white',
                                                )}
                                            >
                                                {project.initials}
                                            </div>
                                            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                                <div className="flex-1 truncate px-4 py-2 text-sm">
                                                    <a href={project.href} className="font-medium text-gray-900 hover:text-gray-600">
                                                        {project.name}
                                                    </a>
                                                    <p className="text-gray-500">{project.members} Steps</p>
                                                </div>
                                                <div className="flex-shrink-0 pr-2">
                                                    {/*<button*/}
                                                    {/*    type="button"*/}
                                                    {/*    onClick={event => {onClose(project.id)}}*/}
                                                    {/*    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
                                                    {/*>*/}
                                                    {/*    <span className="sr-only">Open options</span>*/}
                                                    {/*</button>*/}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TemplateSelector